<template>
  <v-dialog v-model="dialog" fullscreen persistent>
    <template v-slot:activator="{ on, attrs }">
      <p id="fpbDetailItem" v-bind="attrs" v-on="on" @click="loadDetail"></p>
    </template>
    <v-card>
      <div v-if="!loading">
        <v-col class="rcd-back-button" cols="12">
          <div
            cols="12"
            style="
              position: relative;
              padding: 10px 5px 10px;
              display: flex;
              flex-direction: row;
              margin-bottom: 10px;
            "
          >
            <v-icon>mdi-chevron-left</v-icon>
            <div
              style="cursor: pointer; color: indigo"
              @click="closeDetailDialog"
            >
              Back
            </div>
          </div>
        </v-col>
        <div v-if="detail !== null" id="product-detail">
          <div class="product-detail-div">
            <v-card id="rcd" class="row-catalogue-detail1" flat>
              <v-col
                cols="12"
                style="
                  position: relative;
                  border-bottom: none;
                  text-align: left;
                  display: flex;
                  justify-content: space-between;
                "
              >
                <h5>{{ detail.product.name }}</h5>
              </v-col>
              <v-col cols="12" style="position: relative">
                <v-col cols="12" style="padding: 0; margin-bottom: 20px">
                  <p style="font-size: 16px; font-weight: bold">DESKRIPSI</p>
                  <div style="max-height: 100px; overflow: auto">
                    <p>
                      {{ detail.description }}
                    </p>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  style="padding: 0; margin-bottom: 5px; margin-bottom: 5px"
                >
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
                    "
                  >
                    <p style="width: 250px; padding: 0 20px 0 0">Code</p>
                    <p style="padding: 0 20px; font-weight: 500; width: 200px">
                      #{{ detail.product.code }}
                    </p>
                  </div>
                </v-col>
                <v-col cols="12" style="padding: 0; margin-bottom: 5px">
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
                    "
                  >
                    <p style="width: 250px; padding: 0 20px 0 0">Merk</p>
                    <p style="padding: 0 20px; font-weight: 500; width: 200px">
                      {{
                        detail.merk === null || detail.merk === ''
                          ? '-'
                          : detail.merk
                      }}
                    </p>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  style="padding: 0; margin-bottom: 5px; margin-bottom: 5px"
                >
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
                    "
                  >
                    <p style="width: 250px; padding: 0 20px 0 0">
                      Vendor / Supplier
                    </p>
                    <a
                      style="
                        padding: 0 20px;
                        font-weight: 500;
                        color: #1976d2;
                        width: 200px;
                      "
                      class="aab"
                      @click="viewDetailVendor(detail.vendor)"
                    >
                      {{ detail.vendor.name }}
                    </a>
                  </div>
                </v-col>
                <v-col cols="12" style="padding: 0; margin-bottom: 5px">
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
                    "
                  >
                    <p style="width: 250px; padding: 0 20px 0 0">
                      Pembelian Terakhir
                    </p>
                    <p style="padding: 0 20px; font-weight: 500; width: 200px">
                      {{
                        detail.last_ordered === null
                          ? '-'
                          : detail.last_ordered | moment('DD MMMM YYYY')
                      }}
                    </p>
                  </div>
                </v-col>
                <v-col cols="12" style="padding: 0; margin-bottom: 5px">
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
                    "
                  >
                    <p style="width: 250px; padding: 0 20px 0 0">
                      Harga Terakhir
                    </p>
                    <p
                      style="padding: 0 20px; font-weight: 500; width: 200px"
                      v-if="!detail.product.code.includes('AST')"
                    >
                      <span style="margin-right: 5px">{{
                        detail.currency === null ? '' : detail.currency.symbol
                      }}</span>
                      <span>
                        {{
                          detail.last_price === null
                            ? '-'
                            : price(detail.last_price)
                        }}
                      </span>
                    </p>
                    <p
                      style="padding: 0 20px; font-weight: 500; width: 200px"
                      v-else
                    >
                      -
                    </p>
                  </div>
                </v-col>
                <v-col cols="12" style="padding: 0; margin-bottom: 5px">
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
                    "
                  >
                    <p style="width: 250px; padding: 0 20px 0 0">Satuan</p>
                    <p style="padding: 0 20px; font-weight: 500; width: 200px">
                      {{
                        detail.purchase_uom_name === null
                          ? '-'
                          : detail.purchase_uom_name
                      }}
                    </p>
                  </div>
                </v-col>
                <v-col cols="12" style="padding: 0; margin-bottom: 5px">
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
                    "
                  >
                    <p style="width: 250px; padding: 0 20px 0 0">Dibeli Oleh</p>
                    <p style="padding: 0 20px; font-weight: 500; width: 200px">
                      {{
                        detail.last_purchase_plant === null ||
                        detail.last_purchase_plant === ''
                          ? 'PT -'
                          : detail.last_purchase_plant
                      }}
                    </p>
                  </div>
                </v-col>
                <v-col cols="12" style="padding: 0; margin-bottom: 5px">
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
                    "
                  >
                    <p style="width: 250px; padding: 0 20px 0 0">
                      Frekuensi Pembelian
                    </p>
                    <p style="padding: 0 20px; font-weight: 500; width: 200px">
                      {{ detail.purchase_frequency }}
                    </p>
                  </div>
                </v-col>

                <v-col
                  cols="12"
                  style="padding: 0; margin-top: 15px"
                  class="d-flex"
                >
                  <div
                    @click="specDetail(detail, 'main')"
                    style="
                      height: 25px;
                      width: 192px;
                      cursor: pointer;
                      color: indigo;
                    "
                    class="d-flex"
                  >
                    Lihat detail spesifikasi
                    <v-icon style="position: relative; top: -2px"
                      >mdi-chevron-right</v-icon
                    >
                  </div>
                </v-col>
                <v-col cols="12" style="padding: 0; margin-bottom: 20px">
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: flex-end;
                    "
                  >
                    <div>
                      <v-btn
                        color="warning"
                        elevation="1"
                        outlined
                        rounded
                        text
                        style="font-size: 12px"
                        @click="showLocation(getCoordinate)"
                      >
                        <v-icon dark> mdi-map-marker </v-icon>lokasi
                      </v-btn>
                    </div>
                    <div style="margin-left: 10px">
                      <v-btn
                        rounded
                        color="green"
                        :disabled="
                          detail.brochure === null || detail.brochure === ''
                            ? true
                            : false
                        "
                        elevation="1"
                        outlined
                        text
                        style="font-size: 12px"
                        @click="downloadBrochure(detail.brochure)"
                      >
                        <v-icon dark> mdi-download </v-icon>
                        Download Brosur
                      </v-btn>
                      <p
                        v-if="detail.brochure === null"
                        style="
                          margin-top: 2px;
                          border-top: 1px solid red;
                          color: red;
                          font-size: 12px;
                          text-align: center;
                          opacity: 0.5;
                        "
                      >
                        Belum ada brosur
                      </p>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" style="padding: 0; margin-bottom: 5px">
                  <div id="googleMaps" class="gm-close">
                    <GoogleMaps :coordinates="coordinate" />
                  </div>
                </v-col>

                <v-col cols="12" style="position: relative; padding: 0">
                  <div
                    class="text-left"
                    style="padding-left: 20px; position: relative; top: 8px"
                  >
                    <v-btn-toggle
                      v-model="tab.value"
                      color="deep-purple accent-3"
                    >
                      <v-btn
                        elevation="4"
                        v-for="(item, index) in tab.list"
                        :key="index"
                        :value="item.key"
                        style="border-radius: 0 20px 0 0"
                      >
                        {{ item.name }}
                      </v-btn>
                    </v-btn-toggle>
                  </div>
                  <v-col
                    v-if="tab.value == 'supplier'"
                    cols="12"
                    style="position: relative; background: white; padding: 0"
                  >
                    <v-card
                      tile
                      elevation="2"
                      style="
                        border: 1px solid rgba(0, 0, 0, 0.2);
                        padding: 10px;
                      "
                      min-height="100"
                    >
                      <v-data-table
                        dense
                        :loading="loading"
                        loading-text="Load data... Please wait"
                        :headers="otherSupplierHeaders"
                        :items="otherSuppliers"
                        style="border: 1px solid rgba(0, 0, 0, 0.4)"
                      >
                        <template v-slot:[`item.merk`]="{ item }">
                          <div style="width: 70px; font-size: 12px">
                            {{ item.vendor.merk }}
                          </div>
                        </template>
                        <template v-slot:[`item.code`]="{ item }">
                          <div style="width: 70px; font-size: 12px">
                            {{ item.vendor.code }}
                          </div>
                        </template>
                        <template v-slot:[`item.uom`]="{ item }">
                          <div style="width: 70px; font-size: 12px">
                            {{ item.uom_name }}
                          </div>
                        </template>
                        <template v-slot:[`item.supplier`]="{ item }">
                          <div style="width: 210px; font-size: 12px">
                            {{ item.vendor.name }}
                          </div>
                        </template>
                        <template v-slot:[`item.last_price`]="{ item }">
                          <div style="width: 80px; font-size: 12px">
                            {{
                              item.last_price === '-'
                                ? ' Rp. -'
                                : 'Rp. ' + price(item.last_price)
                            }}
                          </div>
                        </template>
                        <template v-slot:[`item.ratting`]="{ item }">
                          <div
                            style="
                              width: 210px;
                              display: flex;
                              flex-direction: row;
                            "
                          >
                            <v-rating
                              small
                              disabled
                              :value="
                                item.vendor.avg_rate !== null
                                  ? Math.round(item.vendor.avg_rate / 2)
                                  : 0
                              "
                              color="yellow darken-3"
                              background-color="grey darken-1"
                              empty-icon="$ratingFull"
                              half-increments
                              hover
                              style="width: 100%; text-align: center"
                            ></v-rating>
                            <p style="margin: auto; font-size: 12px">
                              {{
                                item.vendor.avg_rate !== null
                                  ? (item.vendor.avg_rate / 2).toFixed(1)
                                  : '0.0'
                              }}
                            </p>
                          </div>
                        </template>
                        <template v-slot:[`item.spesifikasi`]="{ item }">
                          <div
                            @click="specDetail(item, 'other')"
                            style="
                              width: 210px;
                              height: 25px;
                              cursor: pointer;
                              color: indigo;
                            "
                          >
                            Lihat detail spesifikasi
                          </div>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                  <v-col
                    v-if="tab.value == 'history'"
                    cols="12"
                    style="position: relative; background: white; padding: 0"
                  >
                    <v-card
                      tile
                      elevation="2"
                      style="
                        border: 1px solid rgba(0, 0, 0, 0.2);
                        padding: 10px;
                      "
                      min-height="100"
                    >
                      <v-data-table
                        mobile-breakpoint="0"
                        dense
                        :loading="loading"
                        loading-text="Load data... Please wait"
                        :headers="purchaseHistoryHeaders"
                        :items="purchaseHistory"
                        :items-per-page="10"
                        style="border: 1px solid rgba(0, 0, 0, 0.4)"
                      >
                        <template v-slot:[`item.product`]="{ item }">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <div
                                v-bind="attrs"
                                v-on="on"
                                style="
                                  text-align: left;
                                  width: 250px;
                                  font-size: 12px;
                                  padding: 0;
                                "
                              >
                                {{
                                  `${
                                    item.product.code !== null
                                      ? '(' + item.product.code + ')'
                                      : ''
                                  } ${item.product.name}`
                                }}
                              </div>
                            </template>
                            <span>
                              {{
                                `${
                                  item.product.code !== null
                                    ? '(' + item.product.code + ')'
                                    : ''
                                } ${item.product.name}`
                              }}</span
                            >
                          </v-tooltip>
                        </template>

                        <template v-slot:[`item.vendor`]="{ item }">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <div
                                v-bind="attrs"
                                v-on="on"
                                style="
                                  text-align: left;
                                  width: 200px;
                                  font-size: 12px;
                                  padding: 0;
                                "
                              >
                                {{
                                  item.vendor !== null
                                    ? `${
                                        item.vendor.code !== null
                                          ? '(' + item.vendor.code + ')'
                                          : ''
                                      } ${
                                        item.vendor.name !== null
                                          ? '(' + item.vendor.name + ')'
                                          : ''
                                      }`
                                    : ''
                                }}
                              </div>
                            </template>
                            <span>{{
                              item.vendor !== null
                                ? `${
                                    item.vendor.code !== null
                                      ? '(' + item.vendor.code + ')'
                                      : ''
                                  } ${
                                    item.vendor.name !== null
                                      ? '(' + item.vendor.name + ')'
                                      : ''
                                  }`
                                : ''
                            }}</span>
                          </v-tooltip>
                        </template>
                        <template
                          v-slot:[`item.budget_price_per_unit`]="{ item }"
                        >
                          <td style="font-size: 12px">
                            {{ price(item.budget_price_per_unit) }}
                          </td>
                        </template>
                        <template v-slot:[`item.budget_price_total`]="{ item }">
                          <td style="font-size: 12px">
                            {{ price(item.budget_price_total) }}
                          </td>
                        </template>
                        <template v-slot:[`item.uom`]="{ item }">
                          <td style="font-size: 12px">
                            {{ item.uom.name }}
                          </td>
                        </template>
                        <template v-slot:[`item.last_price`]="{ item }">
                          <td style="font-size: 12px">
                            {{ price(item.last_price) }}
                          </td>
                        </template>
                        <template v-slot:[`item.request_no`]="{ item }">
                          <div style="width: 120px; font-size: 12px">
                            {{ item.request_no }}
                          </div>
                        </template>
                        <template v-slot:[`item.employee`]="{ item }">
                          <div style="width: 210px; font-size: 12px">
                            {{ item.employee.name }}
                          </div>
                        </template>
                        <template v-slot:[`item.company`]="{ item }">
                          <div style="width: 210px; font-size: 12px">
                            {{ item.company.name }}
                          </div>
                        </template>
                        <template v-slot:[`item.department`]="{ item }">
                          <div style="width: 140px; font-size: 12px">
                            {{ item.department.name }}
                          </div>
                        </template>
                        <template v-slot:[`item.category_item`]="{ item }">
                          <div style="width: 120px; font-size: 12px">
                            {{ item.category_item.name }}
                          </div>
                        </template>
                        <template v-slot:[`item.project`]="{ item }">
                          <div style="width: 210px; font-size: 12px">
                            {{ item.project != null ? item.project.name : '-' }}
                          </div>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                  <v-col
                    v-if="tab.value == 'catalogue'"
                    cols="12"
                    style="position: relative; background: white; padding: 0"
                  >
                    <v-card
                      tile
                      elevation="2"
                      style="
                        border: 1px solid rgba(0, 0, 0, 0.2);
                        padding: 10px;
                      "
                      min-height="100"
                    >
                      <v-data-table
                        mobile-breakpoint="0"
                        dense
                        :loading="loading"
                        loading-text="Load data... Please wait"
                        :headers="vendorCatalogueHeaders"
                        :items="vendorCatalogue"
                        :items-per-page="10"
                        style="border: 1px solid rgba(0, 0, 0, 0.4)"
                        :footer-props="{
                          showFirstLastPage: true,
                          showCurrentPage: true,
                          itemsPerPageOptions: [1, 3, 5, 10, 15, 100, 1000]
                        }"
                      >
                        <template v-slot:[`item.product`]="{ item }">
                          <div style="width: 210px; font-size: 12px">
                            {{ item.product.name }}
                          </div>
                        </template>
                        <template v-slot:[`item.uom`]="{ item }">
                          <div style="width: 70px; font-size: 12px">
                            {{ item.uom_name }}
                          </div>
                        </template>
                        <template v-slot:[`item.currency`]="{ item }">
                          <div style="width: 70px; font-size: 12px">
                            {{ item.currency.code }}
                          </div>
                        </template>
                        <template v-slot:[`item.last_price`]="{ item }">
                          <div style="width: 120px; font-size: 12px">
                            {{ price(item.last_price) }}
                          </div>
                        </template>
                        <template v-slot:[`item.purchase_frequency`]="{ item }">
                          <div style="width: 70px; font-size: 12px">
                            {{ item.purchase_frequency }}
                          </div>
                        </template>
                        <template v-slot:[`item.last_ordered`]="{ item }">
                          <div style="width: 120px; font-size: 12px">
                            {{ item.last_ordered }}
                          </div>
                        </template>
                        <template
                          v-slot:[`item.last_purchase_plant`]="{ item }"
                        >
                          <div style="width: 210px; font-size: 12px">
                            {{ item.last_purchase_plant }}
                          </div>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </v-col>
              </v-col>
            </v-card>
            <div id="rvd" class="row-vendor-detail1">
              <v-icon class="rvd-close-icon" @click="closeDetailVendor" small
                >mdi-close</v-icon
              >
              <div
                class="text-center"
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  padding: 20px 10px;
                  width: 100%;
                  height: 100%;
                  overflow: auto;
                "
              >
                <div v-if="vendorDetail !== null">
                  <div style="margin-top: 10px; height: 170px">
                    <v-img
                      style="height: 170px; margin-bottom: 5px"
                      :src="
                        vendorDetail.logo === null || vendorDetail.logo === ''
                          ? `${getProductImageLink}vendors/logo/Vendor-93zz7xnlhut95uysv2x2b9.png`
                          : `${getProductImageLink}vendors/logo/${photoFilter(
                              vendorDetail.logo
                            )}`
                      "
                      aspect-ratio="1.7"
                      :contain="true"
                    ></v-img>
                  </div>
                  <div
                    style="
                      border-bottom: 1px solid #e0e0e0;
                      margin-top: 15px;
                      text-align: left;
                    "
                  >
                    <p class="vdr-title-p">Name :</p>
                    <p style="font-size: 14px">
                      {{ vendorDetail.name }}
                    </p>
                  </div>
                  <div
                    style="
                      border-bottom: 1px solid #e0e0e0;
                      margin-top: 15px;
                      text-align: left;
                    "
                  >
                    <p class="vdr-title-p">Code :</p>
                    <p style="font-size: 14px">
                      {{ vendorDetail.code }}
                    </p>
                  </div>
                  <div
                    style="
                      border-bottom: 1px solid #e0e0e0;
                      margin-top: 5px;
                      text-align: left;
                    "
                  >
                    <p class="vdr-title-p">Phone :</p>
                    <div style="display: flex; flex-direction: row">
                      <p style="font-size: 14px">
                        {{ phone(vendorDetail) }}
                      </p>
                    </div>
                  </div>
                  <div
                    style="
                      border-bottom: 1px solid #e0e0e0;
                      margin-top: 5px;
                      text-align: left;
                    "
                  >
                    <p class="vdr-title-p">Email :</p>
                    <p style="font-size: 14px">
                      {{
                        vendorDetail.email === '' ||
                        vendorDetail.email === undefined
                          ? '-'
                          : vendorDetail.email
                      }}
                    </p>
                  </div>
                  <div
                    style="
                      border-bottom: 1px solid #e0e0e0;
                      margin-top: 5px;
                      text-align: left;
                    "
                  >
                    <p class="vdr-title-p">Address :</p>
                    <p style="font-size: 14px">
                      {{
                        vendorDetail.address !== '' ? vendorDetail.address : '-'
                      }}
                    </p>
                  </div>
                  <div
                    style="
                      border-bottom: 1px solid #e0e0e0;
                      margin-top: 5px;
                      text-align: left;
                    "
                  >
                    <p class="vdr-title-p">Province :</p>
                    <p style="font-size: 14px">
                      {{
                        vendorDetail.province !== null
                          ? vendorDetail.province.name
                          : '-'
                      }}
                    </p>
                  </div>
                  <div
                    style="
                      border-bottom: 1px solid #e0e0e0;
                      margin-top: 5px;
                      text-align: left;
                    "
                  >
                    <p class="vdr-title-p">City :</p>
                    <p style="font-size: 14px">
                      {{
                        vendorDetail.city !== null
                          ? vendorDetail.city.name
                          : '-'
                      }}
                    </p>
                  </div>
                  <div
                    style="
                      border-bottom: 1px solid #e0e0e0;
                      margin-top: 5px;
                      text-align: left;
                    "
                  >
                    <a href=""></a>
                    <p class="vdr-title-p">Website :</p>
                    <a
                      v-if="vendorDetail.website !== ''"
                      style="font-size: 14px"
                      :href="vendorDetail.website"
                      target="_blank"
                    >
                      {{ vendorDetail.website }}
                    </a>
                    <p v-else>-</p>
                  </div>
                  <div
                    style="
                      border-bottom: 1px solid #e0e0e0;
                      margin-top: 5px;
                      text-align: left;
                    "
                  >
                    <p class="vdr-title-p">Classification :</p>
                    <p style="font-size: 14px">
                      {{
                        vendorDetail.classification !== null
                          ? vendorDetail.classification.name
                          : '-'
                      }}
                    </p>
                  </div>
                  <div
                    style="
                      border-bottom: 1px solid #e0e0e0;
                      margin-top: 5px;
                      text-align: left;
                    "
                  >
                    <p class="vdr-title-p">Sub Classification :</p>
                    <p style="font-size: 14px">
                      {{
                        vendorDetail.sub_classification !== null
                          ? vendorDetail.sub_classification.name
                          : '-'
                      }}
                    </p>
                  </div>
                  <div style="margin-top: 5px; text-align: left">
                    <p class="vdr-title-p">About :</p>
                    <p style="height: 150px; overflow: auto; font-size: 14px">
                      {{
                        vendorDetail.about !== null ? vendorDetail.about : '-'
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <v-card
          v-if="loading"
          class="mx-auto"
          width="100%"
          style="
            z-index: 3;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            position: fixed;
            top: 0;
            height: 100%;
            left: 0;
            right: 0;
            background: rgba(255, 255, 255);
          "
        >
          <v-progress-circular
            :size="170"
            :width="10"
            color="blue"
            indeterminate
            >Please wait</v-progress-circular
          >
        </v-card> -->
          <div>
            <v-dialog fullscreen v-model="spesificationDialog">
              <div
                v-if="selectedVendor !== null"
                style="
                  background: white;
                  padding: 0;
                  overflow: hidden;
                  min-height: 100vh;
                "
              >
                <!-- <v-card
        style="padding:10px; position:relative; background red; overflow:hidden;"
      > -->
                <v-toolbar style="position: relative; z-index: 1" elevation="1">
                  <v-icon style="margin-right: 10px">mdi-compare</v-icon>
                  <v-toolbar-title> DETAIL SPESIFIKASI </v-toolbar-title>
                  <v-divider class="mx-6" inset vertical></v-divider>
                  <v-toolbar-title class="orange--text mb-2">
                    {{ selectedVendor.name }}
                  </v-toolbar-title>
                  <v-divider class="mx-6" inset vertical></v-divider>
                  <v-toolbar-title class="orange--text mb-2">
                    {{ detail.product.name }}
                  </v-toolbar-title>
                  <v-divider class="mx-6" inset vertical></v-divider>
                  <v-toolbar-title class="orange--text mb-2"
                    >#{{ detail.product.code }}</v-toolbar-title
                  >
                  <v-divider class="mx-6" inset vertical></v-divider>
                  <v-toolbar-title class="orange--text mb-2">
                    {{ detail.merk !== '' ? detail.merk : '-' }}
                  </v-toolbar-title>
                  <v-divider class="mx-6" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="closeSpesificationDialog"
                    color="grey"
                    style="color: white"
                  >
                    <v-icon left> mdi-arrow-left-circle </v-icon>
                    Back
                  </v-btn>
                </v-toolbar>
                <v-row
                  class="justify-content-center"
                  style="background: rgba(219, 219, 219, 0.363)"
                >
                  <v-col cols="12" style="padding-bottom: 0">
                    <v-sheet class="mx-auto compare-sheet">
                      <v-carousel
                        hide-delimiters
                        show-arrows
                        class="mx-auto"
                        style="height: 40vh"
                      >
                        <v-carousel-item
                          v-for="(item, index) in specImgArr"
                          :key="index"
                          reverse-transition="fade-transition"
                          transition="fade-transition"
                          style="padding-top: 7px"
                        >
                          <v-img
                            style="height: 300px; margin: auto"
                            aspect-ratio="1.7"
                            contain
                            :src="`${item}`"
                            @error="imgError(index)"
                            alt="product_img"
                          >
                          </v-img>
                        </v-carousel-item>
                      </v-carousel>

                      <div
                        class="mx-auto"
                        style="
                          width: 100%;
                          border: 1px solid rgba(0, 0, 0, 0.2);
                          border-left: none;
                          border-right: none;
                          background: rgba(219, 219, 219, 0.363);
                        "
                      >
                        <v-card-text
                          style="position: relative; color: #2e3634b6"
                        >
                          <v-row>
                            <v-col
                              style="
                                padding: 10px;
                                height: 40px;
                                border-right: 1px solid rgba(0, 0, 0, 0.2);
                              "
                              cols="3"
                              class="title mb-2"
                            >
                              <h6
                                style="
                                  font-size: 12px;
                                  font-weight: 600;
                                  border-radius: 5px;
                                  padding: 0 3px;
                                "
                              >
                                Vendor:
                                <p style="font-size: 14px; font-weight: 400">
                                  {{ selectedVendor.name }}
                                </p>
                              </h6>
                            </v-col>
                            <v-col
                              style="
                                padding: 10px;
                                height: 40px;
                                border-right: 1px solid rgba(0, 0, 0, 0.2);
                              "
                              cols="5"
                              class="title mb-2"
                            >
                              <h6
                                style="
                                  font-size: 12px;
                                  font-weight: 600;
                                  border-radius: 5px;
                                  padding: 0 3px;
                                "
                              >
                                Product: <br />
                                <p style="font-size: 14px; font-weight: 400">
                                  {{ detail.product.name }}
                                </p>
                              </h6>
                            </v-col>
                            <v-col
                              style="
                                padding: 10px;
                                height: 40px;
                                border-right: 1px solid rgba(0, 0, 0, 0.2);
                              "
                              cols="3"
                              class="title mb-2"
                            >
                              <h6
                                style="
                                  font-size: 12px;
                                  font-weight: 600;
                                  border-radius: 5px;
                                  padding: 0 3px;
                                "
                              >
                                Code:
                                <p style="font-size: 14px; font-weight: 400">
                                  #{{ detail.product.code }}
                                </p>
                              </h6>
                            </v-col>
                            <v-col
                              style="padding: 10px; height: 40px"
                              cols="1"
                              class="title mb-2"
                            >
                              <h6
                                style="
                                  font-size: 12px;
                                  font-weight: 600;
                                  border-radius: 5px;
                                  padding: 0 3px;
                                "
                              >
                                Merk:
                                <p style="font-size: 14px; font-weight: 400">
                                  {{ detail.merk !== '' ? detail.merk : '-' }}
                                </p>
                              </h6>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </div>
                    </v-sheet>
                  </v-col>
                  <v-col cols="12" style="padding-top: 0; min-height: 45vh">
                    <v-col cols="12" style="padding: 0">
                      <div
                        class="text-left"
                        style="padding-left: 20px; position: relative; top: 8px"
                      >
                        <v-btn-toggle
                          mandatory
                          v-model="tabName"
                          color="black accent-3"
                          style="background: transparent"
                        >
                          <v-btn
                            elevation="4"
                            v-for="(item, index) in tabFunction(
                              spesificationDetail
                            ) > 0
                              ? spesificationDetail
                              : spesificationDetail"
                            :key="index"
                            :value="index"
                            style="border-radius: 0 20px 0 0"
                          >
                            {{ index }}
                          </v-btn>
                        </v-btn-toggle>
                      </div>
                      <!-- <v-col
                        cols="12"
                        style="
                          position: relative;
                          background: rgba(219, 219, 219, 0.1);
                          padding: 0 5px;
                        "
                      >
                        <v-card
                          v-if="spesificationDetail !== null"
                          tile
                          elevation="2"
                          style="
                            border: 1px solid rgba(0, 0, 0, 0.1);
                            padding: 0px 10px;
                          "
                        >
                          <div>
                            <v-card
                              style="background: rgba(255, 255, 255, 0.1)"
                              elevation="0"
                              id="detailSpesificationTable"
                              v-if="spesificationDetail.catalogue_type === 1"
                            >
                              <v-data-table
                                id="detailSpesificationTable"
                                :headers="spesificationInfomationHeader"
                                :items="spesificationDetail[tabName]"
                                hide-default-footer
                                class="transparent elevation-0 my-4"
                                hide-default-header
                                disable-pagination
                                disable-sort
                              >
                                <template #header="{}">
                                  <thead class="v-data-table-header">
                                    <tr>
                                      <th
                                        v-for="(
                                          h, i
                                        ) in spesificationInfomationHeader"
                                        :key="i"
                                        class="text-center parent-header td-border-style"
                                        :rowspan="h.children ? 1 : 2"
                                        :colspan="
                                          h.children ? h.children.length : 1
                                        "
                                      >
                                        {{ h.text }}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th
                                        v-for="(h1, i1) in getSubHeader(
                                          spesificationInfomationHeader
                                        )"
                                        :key="i1"
                                        class="text-center child-header td-border-style"
                                      >
                                        {{ h1.text }}
                                      </th>
                                    </tr>
                                  </thead>
                                </template>
                                <template #item="props">
                                  <tr>
                                    <td
                                      style="width: 200px; text-align: center"
                                    >
                                      {{
                                        props.item.spesification_attribute.name
                                      }}
                                    </td>
                                    <td
                                      style="width: 250px; text-align: center"
                                    >
                                      {{
                                        props.item.standard !== ''
                                          ? props.item.standard
                                          : '-'
                                      }}
                                    </td>
                                    <td
                                      style="width: 100px; text-align: center"
                                    >
                                      {{
                                        props.item.unit !== null
                                          ? props.item.unit.name
                                          : '-'
                                      }}
                                    </td>
                                    <td
                                      style="width: 100px; text-align: center"
                                    >
                                      {{
                                        props.item.criteria === 1
                                          ? 'Major'
                                          : props.item.criteria === 2
                                          ? 'Kritikal'
                                          : '-'
                                      }}
                                    </td>
                                    <td
                                      style="width: 250px; text-align: center"
                                    >
                                      {{
                                        props.item.analysis_method !== ''
                                          ? props.item.analysis_method
                                          : '-'
                                      }}
                                    </td>
                                    <td
                                      style="width: 250px; text-align: center"
                                    >
                                      {{
                                        props.item.min_frequency_supplier !== ''
                                          ? props.item.min_frequency_supplier
                                          : '-'
                                      }}
                                    </td>
                                    <td
                                      style="width: 250px; text-align: center"
                                    >
                                      {{
                                        props.item.min_frequency_sanqua !== ''
                                          ? props.item.min_frequency_sanqua
                                          : '-'
                                      }}
                                    </td>
                                  </tr>
                                </template>
                              </v-data-table>
                            </v-card>
                            <v-data-table
                              v-if="spesificationDetail.catalogue_type === 2"
                              :headers="genheaders"
                              style="width: 100%"
                              :items="spesificationDetail[tabName]"
                              hide-default-footer
                            >
                            </v-data-table>
                          </div>
                        </v-card>

                        <v-card
                          v-else
                          tile
                          flat
                          style="
                            min-height: 40vh;
                            padding: 0px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background: transparent;
                          "
                        >
                          <v-toolbar-title class="overline">
                            Data not found
                          </v-toolbar-title>
                        </v-card>
                      </v-col> -->
                    </v-col>
                  </v-col>
                </v-row>
                <!-- </v-card> -->
              </div>
            </v-dialog>
          </div>
        </div>
        <div
          v-else
          class="d-flex justify-center align-center"
          style="
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 5;
            background: rgba(0, 0, 0, 0.1);
          "
        >
          <p
            class="d-flex justify-center align-center"
            style="font-size: 12px; margin: auto"
          >
            DATA NOT FOUND
          </p>
        </div>
      </div>
      <div
        v-else
        class="d-flex justify-center align-center"
        style="
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 5;
          background: rgba(0, 0, 0, 0.1);
        "
      >
        <v-progress-circular
          :size="100"
          color="primary"
          indeterminate
          class="d-flex justify-center align-center"
        >
          <p
            class="d-flex justify-center align-center"
            style="font-size: 12px; margin: auto"
          >
            Please wait
          </p>
        </v-progress-circular>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import GoogleMaps from '../../../components/eCatalogue/catalogue_detail/GoogleMaps'
import axios from 'axios'
export default {
  props: ['proc', 'uploadAPI', 'selectedItem'],
  components: {
    GoogleMaps
  },
  data() {
    return {
      dialog: false,
      detail: null,
      loading: false,
      tab: {
        value: 'supplier',
        list: [
          { name: 'Supplier lainnya', key: 'supplier' },
          { name: 'History transaksi', key: 'history' },
          { name: 'Katalog Vendor', key: 'catalogue' }
        ]
      },
      activeImage: '',
      imgArr: [],
      model: null,
      coordinate: {
        lat: 0,
        lng: 0
      },
      catalogueMerk: '',
      vendorDetail: null,
      otherSuppliers: [],
      otherSupplierHeaders: [
        { text: 'Code', value: 'code' },
        { text: 'Supplier', value: 'supplier' },
        { text: 'Merk', value: 'merk' },
        { text: 'UOM', value: 'uom' },
        { text: 'Harga', value: 'last_price' },
        { text: 'Ratting', value: 'ratting', align: 'left' },
        { text: '', value: 'spesifikasi' }
      ],
      getCoordinate: null,
      spesificationDialog: false,
      spesificationDetail: null,

      tabName: 'Dimensi',
      attribut: ['Kualitas', 'Merk', 'Dimensi'],
      specHeaders: [
        {
          text: 'Parameter',
          value: 'spesification_attribute.name',
          width: '20%'
        },
        { text: 'Standar', value: 'standar' },
        { text: 'Unit', value: 'unit' },
        { text: 'Kriteria', value: 'kriteria' },
        {
          text: 'Metode Analisa',
          value: 'metode_analisa'
        },
        {
          text: 'Minimum Frekuensi',
          value: 'minimum_frekuensi'
        }
      ],
      genheaders: [
        {
          text: 'Parameter',
          value: 'spesification_attribute.name',
          width: '20%'
        },
        {
          text: 'Deskripsi',
          value: 'description'
        }
      ],

      spesificationInfomationHeader: [
        {
          text: 'Parameter',
          align: 'start',
          sortable: false,
          value: 'spesification_attribute.name'
        },
        { text: 'Standar', value: 'standard' },
        { text: 'Unit', value: 'unit.name' },
        { text: 'Kriteria', value: 'criteria' },
        {
          text: 'Metode Analisa',
          value: 'analysis_method'
        },
        {
          text: 'Minimum Frekuensi',
          value: 'min_frequency_sanqua',
          divider: true,
          children: [{ text: 'Supplier' }, { text: 'Sanqua' }]
        }
      ],
      specImgArr: [],
      selectedVendor: null,
      purchaseHistory: [],
      purchaseHistoryHeaders: [
        {
          text: 'No. Request',
          value: 'request_no',
          align: 'left',
          sortable: false
        },
        {
          text: 'Product',
          value: 'product',
          align: 'left',
          sortable: false
        },
        {
          text: 'Vendor',
          value: 'vendor',
          align: 'left',
          sortable: false
        },
        {
          text: 'Qty',
          value: 'qty',
          align: 'left',
          sortable: false
        },
        {
          text: 'Uom',
          value: 'uom',
          align: 'left',
          sortable: false
        },
        {
          text: 'Budget/unit',
          value: 'budget_price_per_unit',
          align: 'left',
          sortable: false
        },
        {
          text: 'Total',
          value: 'budget_price_total',
          align: 'left',
          sortable: false
        },
        {
          text: 'Harga Terakhir',
          value: 'last_price',
          align: 'left',
          sortable: false
        },
        {
          text: 'PIC',
          value: 'employee',
          align: 'left',
          sortable: false
        },
        {
          text: 'Company',
          value: 'company',
          align: 'left',
          sortable: false
        },
        {
          text: 'Department',
          value: 'department',
          align: 'left',
          sortable: false
        },
        {
          text: 'Category',
          value: 'category_item',
          align: 'left',
          sortable: false
        },
        {
          text: 'Project',
          value: 'project',
          align: 'left',
          sortable: false
        }
        // {
        //   text: 'Status',
        //   value: 'item_status',
        //   align: 'left',
        //   sortable: false
        // }
      ],

      vendorCatalogue: [],
      vendorCatalogueHeaders: [
        {
          text: 'Product',
          value: 'product',
          align: 'left',
          sortable: false
        },
        {
          text: 'Satuan',
          value: 'uom',
          align: 'left',
          sortable: false
        },
        {
          text: 'Currency',
          value: 'currency',
          align: 'left',
          sortable: false
        },
        {
          text: 'Harga terakhir',
          value: 'last_price',
          align: 'left',
          sortable: false
        },
        {
          text: 'Terakhir dibeli',
          value: 'last_ordered',
          align: 'left',
          sortable: false
        },
        {
          text: 'Dibeli oleh',
          value: 'last_purchase_plant',
          align: 'left',
          sortable: false
        },
        {
          text: 'Frekuensi',
          value: 'purchase_frequency',
          align: 'left',
          sortable: false
        }
      ]
    }
  },
  watch: {
    model() {
      this.activeImage = this.imgArr[this.model]
    }
  },
  computed: {
    ...mapGetters(['getProductImageLink'])
  },
  methods: {
    closeSpesificationDialog() {
      setTimeout(() => {
        this.spesificationDetail = null
        this.selectedVendor = null
      }, 600)
      setTimeout(() => {
        this.spesificationDialog = false
        this.specImgArr = []
      }, 100)
    },
    getSpesificationImage() {
      const arr = Object.values(this.detail.photo)
      const img = []
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] !== null) {
          img.push(
            arr[i].replace(
              'http://localhost/uploads/',
              `${this.getProductImageLink}`
            )
          )
        } else {
          if (i === 0) {
            img.push(
              'https://e-sanqua.sanquawater.co.id/image/ecatalogue/products/photo_1/ProductPhoto-ys19dvd8nvl3xqlu1o4u2z.png'
            )
          }
        }
      }
      this.specImgArr = img
    },
    specImgError(index) {
      this.images[index].photo =
        'http://10.10.20.8:8020/products/photo_1/ProductPhoto-g8extzpjvxqb5hcpc51be.png'
    },
    tabFunction(data) {
      return data
    },
    getSubHeader(headers) {
      let result = []
      headers
        .filter((i) => i.children)
        .forEach((v) => {
          result = result.concat(v.children)
        })
      return result
    },

    photoFilter(link) {
      const lastIndxOf = link.lastIndexOf('/')
      const str = link.substring(lastIndxOf + 1)
      console.log(str)
      if (str === 'null' || str === '') {
        return 'Vendor-93zz7xnlhut95uysv2x2b9.png'
      } else {
        return str
      }
    },
    phone(item) {
      if (item.phone1 === undefined && item.phone2 === undefined) {
        return '-'
      } else if (item.phone1 !== undefined && item.phone2 === undefined) {
        if (item.phone1 === '') {
          return '-'
        } else {
          return item.phone1
        }
      } else if (item.phone1 === undefined && item.phone2 !== undefined) {
        if (item.phone2 === '') {
          return '-'
        } else {
          return item.phone2
        }
      } else {
        if (item.phone1 === '' && item.phone2 === '') {
          return '-'
        } else if (item.phone1 !== '' && item.phone2 === '') {
          return item.phone1
        } else if (item.phone1 === '' && item.phone2 !== '') {
          return item.phone2
        } else {
          return item.phone1 + ' / ' + item.phone2
        }
      }
    },
    async specDetail(item, param2, param3) {
      console.log('vendor???', item)
      if (param2 === 'main') {
        this.catalogueMerk = item.merk
      } else {
        this.catalogueMerk = item.vendor.merk
      }
      this.selectedVendor = item.vendor

      setTimeout(() => {
        this.spesificationDialog = true
      }, 300)
      await axios
        .get(
          `${
            this.proc
          }vendor/catalogue_spesification/list?offset=${0}&limit=${1000}&keyword=${''}&vendor_catalogue_id=${
            item.id
          }&mode=public`
        )
        .then((response) => {
          console.log('getDetailSpesification>>>', response.data)
          if (response.data.status_code == '00') {
            this.spesificationDetail = response.data.data
            this.getSpesificationImage()
          }
          return null
        })
        .catch((error) => {
          console.log(error)
          return null
        })
    },
    downloadBrochure(item) {
      axios({
        url: `${this.uploadAPI}download/vendor_catalogue/brochure/${item}`,
        method: 'GET',
        responseType: 'blob'
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', item)
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    showLocation(item) {
      if (item !== null) {
        const element = document.getElementById('googleMaps')
        if (element.classList[0] === 'gm-close') {
          element.classList.remove('gm-close')
          element.classList.add('gm-open')
          if (item.latitude === null && item.longitude === null) {
            this.coordinate = {
              lat: -6.257035162544363,
              lng: 106.76446994117893
            }
          } else {
            this.coordinate = {
              lat: Number(item.latitude),
              lng: Number(item.longitude)
            }
          }
        } else {
          element.classList.remove('gm-open')
          element.classList.add('gm-close')
          this.coordinate = {
            lat: -6.257035162544363,
            lng: 106.76446994117893
          }
        }
      }
    },
    viewDetailVendor(data) {
      axios
        .get(`${this.proc}vendor/detail/${data.id}`)
        .then((response) => {
          if (response.data.status_code == '00') {
            this.vendorDetail = response.data.data
          }
        })
        .catch((error) => {
          console.log(error)
        })

      const element1 = document.getElementById('rcd')
      const element2 = document.getElementById('rvd')

      element1.classList.remove('row-catalogue-detail1')
      element2.classList.remove('row-vendor-detail1')
      element1.classList.add('row-catalogue-detail2')
      element2.classList.add('row-vendor-detail2')
    },
    closeDetailVendor() {
      const element1 = document.getElementById('rcd')
      const element2 = document.getElementById('rvd')
      element1.classList.remove('row-catalogue-detail2')
      element2.classList.remove('row-vendor-detail2')
      element1.classList.add('row-catalogue-detail1')
      element2.classList.add('row-vendor-detail1')
    },
    initImage() {
      const arr = Object.values(this.detail.photo)
      const img = []

      // if (arr.findIndex(element => element === null) !== -1) {
      //   arr.splice(arr.findIndex(element => element === null))
      // }
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] !== null) {
          img.push(
            arr[i].replace(
              'http://localhost/uploads/',
              `${this.getProductImageLink}`
            )
          )
        } else {
          if (i === 0) {
            img.push(
              'https://e-sanqua.sanquawater.co.id/image/ecatalogue/products/photo_1/ProductPhoto-ys19dvd8nvl3xqlu1o4u2z.png'
            )
          }
        }
      }
      this.imgArr = img
    },
    closeDetailDialog() {
      setTimeout(() => {
        this.dialog = false
        this.otherSuppliers = []
        this.purchaseHistory = []
        this.vendorCatalogue = []
        this.detail = null
      }, 300)
    },
    async loadDetail() {
      this.loading = true
      let url = `${this.proc}vendor/catalogue/list?keyword=${this.selectedItem.product.code}&offset=0&limit=16&vendor_id=${this.selectedItem.vendor.id}`
      if (this.selectedItem.product.code.includes('AST')) {
        url = url + '&is_asset=1'
      }
      axios
        .get(url)
        .then((res) => {
          if (res.data.status_code == '00') {
            this.detail = res.data.data[0]
            this.getOtherSuppliers(res.data.data[0])
            this.getPurchaseTransactionHistory()
            this.getVendorCatalogue()
            setTimeout(() => {
              this.loading = false
              this.dialog = true
            }, 200)
          } else {
            this.loading = false
          }
        })
        .catch((err) => {
          this.showMsgDialog('error', `${err}`, false)
          this.loading = false
        })
    },
    getOtherSuppliers(data) {
      axios
        .get(`${this.proc}vendor/catalogue/detail/${data.id}`)
        .then((res) => {
          if (res.data.status_code == '00') {
            this.getCoordinate = res.data.data.vendor_location
            this.otherSuppliers = res.data.data.other_vendor
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getPurchaseTransactionHistory() {
      axios
        .get(
          `${this.proc}fpb/transaction_history?product_code=${this.selectedItem.product.code}&vendor_id=${this.selectedItem.vendor.id}&offset=0&limit=all`
        )
        .then((res) => {
          if (res.data.status_code == '00') {
            this.purchaseHistory = res.data.data
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getVendorCatalogue() {
      axios
        .get(
          `${this.proc}vendor/catalogue/list?offset=0&keyword=&limit=all&vendor_id=${this.selectedItem.vendor.id}`
        )
        .then((res) => {
          console.log('getVendorCatalogue>>>', res)
          if (res.data.status_code == '00') {
            this.vendorCatalogue = res.data.data
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },

    price(pItem) {
      if (pItem !== undefined) {
        if (pItem !== null) {
          return pItem.toString().replace(/\B(?<!\\d*)(?=(\d{3})+(?!\d))/g, ',')
        }
      }
      return ''
    }
  }
}
</script>
<style scoped lang="scss">
.vdr-title-p {
  font-weight: 600;
}
#product-detail {
  p,
  h6,
  h4 {
    margin: 0;
  }
}
#googleMaps {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
}
.gm-open {
  height: 400px;
}
.gm-close {
  height: 0;
}
.aab:hover {
  text-decoration: 1px underline;
}
.product-detail-div {
  min-height: 50vh;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
#rcd {
  height: 100%;
  position: relative;
  transition: 0.3s;

  // .rcd-back-button {
  //   display: none;
  // }
}
.row-catalogue-detail1 {
  width: 100%;
}
.row-catalogue-detail2 {
  width: 70%;
}
#rvd {
  overflow: hidden;
  background: white;
  margin-left: 10px;
  position: relative;
  transition: 0.3s;
  .rvd-close-icon {
    position: relative;
    left: 5px;
    cursor: pointer;
    z-index: 2;
    border-radius: 50%;
    border: 1px solid black;
    box-shadow: 2px 1px 3px rgba(0, 0, 0, 0.3);
  }
}
.row-vendor-detail1 {
  width: 0;
}
.row-vendor-detail2 {
  width: 30%;
}

#rci {
  height: 100%;
}

.row-catalogue-image1 {
  width: 30%;
  padding: 0 30px;
}
@media (max-width: 576px) {
  #rvd {
    position: fixed;
    left: 0;
    top: 120px;
    right: 0;
    z-index: 3;
  }
  .row-vendor-detail1 {
    height: 0;
    border: none;
    width: 0;
    border-radius: 5px;
  }
  .row-vendor-detail2 {
    height: 80vh;
    border: 1px solid grey;
    width: 95%;
    border-radius: 5px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  }
  .row-catalogue-detail1 {
    width: 100%;
  }
  .row-catalogue-detail2 {
    width: 100%;
  }
  #rci {
    display: none;
  }
  #rcd {
    .rcd-back-button {
      display: flex;
    }
  }
}
</style>
